const mapper = new Map<string, string>(
  [
    ['Afghanistan', 'Afghan'],
    ['Albania', 'Albanian'],
    ['Algeria', 'Algerian'],
    ['Andorra', 'Andorran'],
    ['Angola', 'Angolan'],
    ['Argentina', 'Argentinian'],
    ['Armenia', 'Armenian'],
    ['Australia', 'Australian'],
    ['Austria', 'Austrian'],
    ['Azerbaijan', 'Azerbaijani'],
    ['Bahamas', 'Bahamian'],
    ['Bahrain', 'Bahraini'],
    ['Bangladesh', 'Bangladeshi'],
    ['Barbados', 'Barbadian'],
    ['Belarus', 'Belarusian'],
    ['Belgium', 'Belgian'],
    ['Belize', 'Belizean'],
    ['Benin', 'Beninese'],
    ['Bhutan', 'Bhutanese'],
    ['Bolivia', 'Bolivian'],
    ['Bosnia-Herzegovina', 'Bosnian'],
    ['Botswana', 'Botswanan'],
    ['Brazil', 'Brazilian'],
    ['Britain', 'British'],
    ['Brunei', 'Bruneian'],
    ['Bulgaria', 'Bulgarian'],
    ['Burkina', 'Burkinese'],
    ['Myanmar', 'Burmese'],
    ['Burundi', 'Burundian'],
    ['Cambodia', 'Cambodian'],
    ['Cameroon', 'Cameroonian'],
    ['Canada', 'Canadian'],
    ['Cape Verde Islands', 'Cape Verdean'],
    ['Chad', 'Chadian'],
    ['Chile', 'Chilean'],
    ['China', 'Chinese'],
    ['Colombia', 'Colombian'],
    ['Congo', 'Congolese'],
    ['Costa Rica', 'Costa Rican'],
    ['Croatia', 'Croat'],
    ['Cuba', 'Cuban'],
    ['Cyprus', 'Cypriot'],
    ['Czech Republic', 'Czech'],
    ['Denmark', 'Danish'],
    ['Djibouti', 'Djiboutian'],
    ['Dominica', 'Dominican'],
    ['Dominican Republic', 'Dominican'],
    ['Ecuador', 'Ecuadorean'],
    ['Egypt', 'Egyptian'],
    ['El Salvador', 'Salvadorean'],
    ['England', 'English'],
    ['Eritrea', 'Eritrean'],
    ['Estonia', 'Estonian'],
    ['Ethiopia', 'Ethiopian'],
    ['Fiji', 'Fijian'],
    ['Finland', 'Finnish'],
    ['France', 'French'],
    ['Gabon', 'Gabonese'],
    ['Gambia', 'Gambian'],
    ['Georgia', 'Georgian'],
    ['Germany', 'German'],
    ['Ghana', 'Ghanaian'],
    ['Greece', 'Greek'],
    ['Grenada', 'Grenadian'],
    ['Guatemala', 'Guatemalan'],
    ['Guinea', 'Guinean'],
    ['Guyana', 'Guyanese'],
    ['Haiti', 'Haitian'],
    ['Netherlands', 'Dutch'],
    ['Honduras', 'Honduran'],
    ['Hungary', 'Hungarian'],
    ['Iceland', 'Icelandic'],
    ['India', 'Indian'],
    ['Indonesia', 'Indonesian'],
    ['Iran', 'Iranian'],
    ['Iraq', 'Iraqi'],
    ['Ireland', 'Irish'],
    ['Italy', 'Italian'],
    ['Jamaica', 'Jamaican'],
    ['Japan', 'Japanese'],
    ['Jordan', 'Jordanian'],
    ['Kazakhstan', 'Kazakh'],
    ['Kenya', 'Kenyan'],
    ['Kuwait', 'Kuwaiti'],
    ['Laos', 'Laotian'],
    ['Latvia', 'Latvian'],
    ['Lebanon', 'Lebanese'],
    ['Liberia', 'Liberian'],
    ['Libya', 'Libyan'],
    ['Lithuania', 'Lithuanian'],
    ['Macedonia', 'Macedonian'],
    ['Madagascar', 'Madagascan'],
    ['Malawi', 'Malawian'],
    ['Malaysia', 'Malaysian'],
    ['Maldives', 'Maldivian'],
    ['Mali', 'Malian'],
    ['Malta', 'Maltese'],
    ['Mauritania', 'Mauritanian'],
    ['Mauritius', 'Mauritian'],
    ['Mexico', 'Mexican'],
    ['Moldova', 'Moldovan'],
    ['Monaco', 'Monégasque'],
    ['Mongolia', 'Mongolian'],
    ['Montenegro', 'Montenegrin'],
    ['Morocco', 'Moroccan'],
    ['Mozambique', 'Mozambican'],
    ['Namibia', 'Namibian'],
    ['Nepal', 'Nepalese'],
    ['New Zealand', 'New Zealand'],
    ['Nicaragua', 'Nicaraguan'],
    ['Niger', 'Nigerien'],
    ['Nigeria', 'Nigerian'],
    ['North Korea', 'North Korean'],
    ['Norway', 'Norwegian'],
    ['Oman', 'Omani'],
    ['Pakistan', 'Pakistani'],
    ['Panama', 'Panamanian'],
    ['Papua New Guinea', 'Papua New Guinean'],
    ['Paraguay', 'Paraguayan'],
    ['Peru', 'Peruvian'],
    ['Philippines', 'Philippine'],
    ['Poland', 'Polish'],
    ['Portugal', 'Portuguese'],
    ['Qatar', 'Qatari'],
    ['Romania', 'Romanian'],
    ['Russia', 'Russian'],
    ['Rwanda', 'Rwandan'],
    ['Saudi Arabia', 'Saudi Arabian'],
    ['Scotland', 'Scottish'],
    ['Senegal', 'Senegalese'],
    ['Serbia', 'Serb'],
    ['Seychelles', 'Seychellois'],
    ['Sierra Leone', 'Sierra Leonian'],
    ['Singapore', 'Singaporean'],
    ['Slovakia', 'Slovak'],
    ['Slovenia', 'Slovenian'],
    ['Somalia', 'Somali'],
    ['South Africa', 'South African'],
    ['South Korea', 'South Korean'],
    ['Spain', 'Spanish'],
    ['Sri Lanka', 'Sri Lankan'],
    ['Sudan', 'Sudanese'],
    ['Suriname', 'Surinamese'],
    ['Swaziland', 'Swazi'],
    ['Sweden', 'Swedish'],
    ['Switzerland', 'Swiss'],
    ['Syria', 'Syrian'],
    ['Taiwan', 'Taiwanese'],
    ['Tajikistan', 'Tajik'],
    ['Tanzania', 'Tanzanian'],
    ['Thailand', 'Thai'],
    ['Togo', 'Togolese'],
    ['Trinidad', 'Trinidadian'],
    ['Tunisia', 'Tunisian'],
    ['Turkey', 'Turkish'],
    ['Turkmenistan', 'Turkmen'],
    ['Tuvalu', 'Tuvaluan'],
    ['Uganda', 'Ugandan'],
    ['Ukraine', 'Ukrainian'],
    ['United Arab Emirates', 'Emirate'],
    ['United Kingdom', 'British'],
    ['United States of America', 'American'],
    ['Uruguay', 'Uruguayan'],
    ['Uzbekistan', 'Uzbek'],
    ['Vanuatu', 'Vanuatuan'],
    ['Venezuela', 'Venezuelan'],
    ['Vietnam', 'Vietnamese'],
    ['Wales', 'Welsh'],
    ['Western Samoa', 'Western Samoan'],
    ['Yemen', 'Yemeni'],
    ['Yugoslavia', 'Yugoslav'],
    ['Zaire', 'Zaïrean'],
    ['Zambia', 'Zambian'],
    ['Zimbabwe', 'Zimbabwean']
  ]
);

export const map = (nation: string): string | undefined => mapper.get(nation);
